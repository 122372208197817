import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {eye} from 'react-icons-kit/icomoon/eye'
import {eyeBlocked} from 'react-icons-kit/icomoon/eyeBlocked'
import { Icon } from 'react-icons-kit'

import api from '../services/api'

import './styles/Login.css';

import logo from '../imgs/logo.png';
import delphus from '../imgs/delphus.png';
import { CircularProgress } from '@material-ui/core';

const swal = withReactContent(Swal)

function Login() {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
    const [mostraSenha, setMostraSenha] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (auth.authenticated) window.location.href = '/intcc/home'
    }, [auth])

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        
        const form = document.getElementById('loginForm');
        const data = new FormData(form);
        
        if (data.get('login') === '') {
            
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        setLoading(true)
        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) { 
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/login', object)
            .then(function (response) {
                console.log(response.data)
                let { PK_USU, NOME, CAD } = response.data[0];
                if (PK_USU && PK_USU > 0) {
                    localStorage['authIntcc'] = JSON.stringify({ authenticated: true, user: PK_USU, cad: CAD, name: NOME, last_activity: new Date(), checkin: response.data.checkin? true: false,
                         comprovante: response.data.comprovante? true: false })
                    window.location.href = '/intcc/home'
                } else {
                    swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            });
    }

    return (
        <div>
            <div className="Login">
                <div className="form">
                    <form className="form-signin" id="loginForm" onSubmit={login}>
                        <img alt="logo" src={logo} title="intcc" className="logo" />
                        <div className="form-label-group">
                            <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário" />
                            <label htmlFor="login">Login</label>
                        </div>
                        <div className="form-label-group" style={{display: 'flex', alignItems: 'center'}}>
                            <input type={mostraSenha ? 'text' : 'password'} id="senha" name="senha" className="loginInput" placeholder="Senha" style={{width: '100%', paddingRight: '30px'}}/>
                            {mostraSenha ? 
                                <Icon onClick={()=> {setMostraSenha(!mostraSenha)}} icon={eyeBlocked} size={16} style={{cursor: 'pointer', position: 'relative', left: '-25px', width: 1, top: '0px'}}/>
                                :
                                <Icon onClick={()=> {setMostraSenha(!mostraSenha)}} icon={eye} size={16} style={{cursor: 'pointer', position: 'relative', left: '-25px', width: 1, top: '0px'}}/>
                            }

                            <label htmlFor="senha">Senha</label>
                        </div>
                        <button className="loginButton" type="submit" rel="nofollow" disabled={loading}>
                            <span><p className='loginButtonText'>{
                                loading ?
                                <CircularProgress style={{marginTop: 6, color: '#FF6900'}}  size={15}/>
                                :
                                'Entrar'
                            
                            }</p></span>
                            
                        </button>
                        <div className="forgotPwDiv">
                        <a href='/intcc/forgotPw'>Esqueci minha senha</a>
                    </div>

                    </form>
                </div>
            </div>
            <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div>


        </div>
    );
}

export default Login;
